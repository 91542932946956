<template>
  <div class="faq-form">
    <v-row>
      <v-col cols="12" md="4">
        <v-form ref="form" class="faq-form__form" @submit.prevent="submit">
          <h3 class="py-5 primary--text">{{ header }}</h3>
          <label class="text-field-label">Buildings</label>
          <SelectMultipleBuildings
            flat
            solo
            required
            class="mt-2"
            :label="'Select buildings'"
            :placeholder="'Select buildings'"
            v-model="form.building_id"
            :pre-select="!isUpdate"
            :error-messages="form.$getError('building_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
            :deletableChips="true"
            :isHasSelectAll="true"
            :items="folder ? folder.buildings : []"
          />
          <label class="text-field-label">Folder Name</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Name"
            v-model="form.name"
            :error-messages="form.$getError('name')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>
          <v-checkbox
            v-model="form.is_active"
            hide-details="auto"
            label="Set as active"
            class="mt-0"
          ></v-checkbox>
          <div class="mt-4" v-if="hasPermissions">
            <v-btn
              type="submit"
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              :loading="form.$busy"
              >{{ buttonLabel }}</v-btn
            >
          </div>
        </v-form>
      </v-col>
      <v-col class="table_document_data" cols="12" md="8">
        <v-row class="row_documents_table">
          <v-col class="font_weight_700" cols="12" md="6">Documents</v-col>
          <v-col class="button_document_table_create" cols="12" md="6">
            <v-btn
              v-if="hasPermissions"
              color="primary"
              class="appbar__btn"
              height="40px"
              @click="dialogUpdate = true"
            >
              <v-icon class="mr-2">{{ icons.add }}</v-icon>
              Add Documents
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          :headers="isReadOnly ? readonlyHeaders : headerDocuments"
          :items="isUpdate ? documents : tempDocuments"
          v-model="selected"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': [5, 10, 15, -1],
          }"
          :items-per-page="5"
          :loading="loading"
          @update:options="fetchDocuments"
          :server-items-length="
            isUpdate ? totalDocuments : tempDocuments.length
          "
          @click:row="editItem"
        >
          <template v-slot:item.file="{ item }">
            <div v-if="item.file">{{ item.file.name }}</div>
          </template>
          <template v-if="hasPermissions" v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click.stop="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogUpdate" max-width="600px">
      <v-form
        ref="formDocumentsFile"
        class="faq-form__form"
        @submit.prevent="submitDocumentsFile"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Open Dialog
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ headerModal }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <label class="text-field-label">File Input</label>
                  <v-file-input
                    v-model="formDocumentsFile.file"
                    :loading="formDocumentsFile.$busy"
                    :disabled="formDocumentsFile.$busy"
                    class="mt-2"
                    flat
                    solo
                    clearable
                    show-close="false"
                    :error-messages="
                      isFileTooLarge
                        ? 'The file must be less than 25MB'
                        : formDocumentsFile.$getError('file')
                    "
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <label class="text-field-label">Title</label>
                  <v-text-field
                    flat
                    solo
                    required
                    class="mt-2"
                    placeholder="Title"
                    v-model="formDocumentsFile.title"
                    :loading="formDocumentsFile.$busy"
                    :disabled="formDocumentsFile.$busy"
                    :error-messages="formDocumentsFile.$getError('title')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 "
              text
              @click="reviewFile()"
              :disabled="!formDocumentsFile.file"
            >
              View File
            </v-btn>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 "
              text
              @click="cancelDialogUpdate()"
            >
              Cancel
            </v-btn>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 text-uppercase"
              text
              @click="submitDocumentsFile()"
              name="submitFormDocument"
              :loading="formDocumentsFile.$busy"
              :disabled="
                isFileTooLarge ||
                !formDocumentsFile.title ||
                !formDocumentsFile.file
              "
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="600px">
      <v-form
        ref="formDocumentsFile"
        class="faq-form__form"
        @submit.prevent="submitDocumentsFile"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5"
              >Are you sure to delete this document(s) ?</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelDialogDelete()">
              No
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="confirmDelete()"
              name="submitFormDocument"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Hub FAQ Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import SelectMultipleBuildings from '@/components/fields/SelectMultipleBuildings.vue'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import ROLE from '@/utils/enums/Role'

export default {
  components: {
    SelectMultipleBuildings,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    folder: {
      type: Object,
      default: () => {
        return null
      },
    },
    accessBuilding: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: new Form({
        building_id: [],
        name: '',
        is_active: false,
        document_ids: [],
      }),
      formDocumentsFile: new Form({
        file: null,
        title: '',
      }),
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      headerDocuments: [
        {
          text: 'Title',
          value: 'title',
        },
        {
          text: 'File name',
          value: 'file',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'end',
        },
      ],
      readonlyHeaders: [
        {
          text: 'Title',
          value: 'title',
        },
        {
          text: 'File name',
          value: 'file',
        },
      ],
      selected: [],
      loading: false,
      dialogUpdate: false,
      isUpdateDocuments: false,
      dialogDelete: false,
      deleteDocumentId: '',
      options: {},
    }
  },

  created() {
    this.clearTempDocumentsList()
    this.fetchDocuments()
  },

  computed: {
    ...mapState({
      createdFolder: (state) => state.folder.folderDetails,
      totalDocuments: (state) => state.document.totalDocuments,
      documents: (state) => state.document.list,
      createdDocument: (state) => state.document.documentDetails,
      errorDocument: (state) => state.document.error,
      tempDocuments: (state) => state.document.tempList,
      isFileTooLarge() {
        if (this.formDocumentsFile.file) {
          return this.formDocumentsFile.file.size > 25 * 1024 * 1024
        }
        return false
      },
      permissions: (state) => state.auth.permissions,
      hasPermissions() {
        if (this.isUpdate) {
          return (
            validatePermissions(
              [PERMISSION.DOCUMENTS_UPDATE],
              this.permissions
            ) && this.accessBuilding
          )
        } else {
          return validatePermissions(
            [
              PERMISSION.DOCUMENTS_CREATE,
              PERMISSION.DOCUMENTS_DELETE,
              PERMISSION.DOCUMENTS_UPDATE,
            ],
            this.permissions
          )
        }
      },
      isReadOnly: (state) => state.auth.role === ROLE.READ_ONLY ?? false,
    }),

    header() {
      return this.isUpdate ? 'Document information' : 'Create Documents Folder'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updateFolder : this.createFolder
    },

    submitApiDocumentsFile() {
      return this.isUpdateDocuments ? this.updateDocument : this.createDocument
    },

    clearTempDocumentsList() {
      return this.clearTempDocuments
    },

    isUpdate() {
      return !!this.folder
    },

    headerModal() {
      return this.isUpdate ? 'Edit Document' : 'New Document'
    },
  },

  watch: {
    folder(newValue, oldValue) {
      this.initForm()
      this.fetchDocuments()
    },
  },

  methods: {
    ...mapActions({
      getDocuments: 'document/getDocuments',
      createFolder: 'folder/createFolder',
      updateFolder: 'folder/updateFolder',
      createDocument: 'document/createDocument',
      updateDocument: 'document/updateDocument',
      deleteDocument: 'document/deleteDocument',
      clearError: 'document/clearError',
      clearTempDocuments: 'document/clearTempDocuments',
      deleteTempDocument: 'document/deleteTempDocument',
    }),

    initForm() {
      if (this.folder) {
        const buildingIds = [...this.folder.buildings].map((item) => item.id)
        this.form.building_id = buildingIds
        this.form.name = this.folder.name
        this.form.is_active = this.folder.is_active
      }
    },

    async submit() {
      if (this.form.$busy) return

      this.form.$busy = true
      this.form.$clearErrors()

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('Folder details successfully updated!')
          } else {
            this.showSnackbar('Folder successfully created!')
            this.$router.push({
              name: 'hub.documents',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    getFormData() {
      let form = this.form.$data()
      if (this.isUpdate) {
        form.id = this.folder.id
      }
      form.document_ids = this.tempDocuments.map((item) => item.id)
      return form
    },

    async getFormDocumentData() {
      if (
        this.formDocumentsFile.file?.original_url &&
        this.formDocumentsFile.file?.id
      ) {
        const formData = new FormData()
        formData.append('folder_id', this.folder?.id || 0)
        formData.append('title', this.formDocumentsFile.title)
        formData.append('id', this.formDocumentsFile.id)
        return formData
      } else {
        const data = new Promise((resolve, reject) => {
          let id = this.formDocumentsFile.id || null
          let formDocumentsFile = this.formDocumentsFile.$data()
          formDocumentsFile.folder_id = this.folder?.id || 0
          let file = ''
          let byteArray
          const blobOrFile = formDocumentsFile.file
          const fileReader = new FileReader()
          fileReader.onload = () => {
            byteArray = new Uint8Array(fileReader.result)
            if (formDocumentsFile.file) {
              const blob = new Blob([byteArray], {
                type: 'application/octet-stream',
                size: byteArray.length,
              })
              const fileName = formDocumentsFile.file.name
              file = new File([blob], fileName, {
                type: blob.type,
                lastModified: Date.now(),
                size: formDocumentsFile.file.size,
              })
              formDocumentsFile.file = file
            }
            const formData = new FormData()
            formData.append('file', file)
            formData.append('folder_id', formDocumentsFile.folder_id)
            formData.append('title', formDocumentsFile.title)
            formData.append('id', id)
            resolve(formData)
          }

          fileReader.onerror = (error) => {
            reject(error)
          }

          fileReader.readAsArrayBuffer(blobOrFile)
        })
        return data
      }
    },

    async fetchDocuments(options, page = 1) {
      if (this.loading) return
      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalDocuments
            : options.itemsPerPage
          : 5,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
        folderId: this.$route?.params?.id || null,
      }
      this.loading = true
      await this.getDocuments(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    async submitDocumentsFile() {
      if (this.formDocumentsFile.$busy) return
      this.formDocumentsFile.$busy = true
      this.formDocumentsFile.$clearErrors()
      let forUpdate = this.isUpdateDocuments
      const formData = await this.getFormDocumentData()

      await this.submitApiDocumentsFile(formData)
        .then((res) => {
          if (this.errorDocument) {
            this.showSnackbar(this.errorDocument, false)
            this.clearError()
          } else {
            if (forUpdate) {
              this.formDocumentsFile.$busy = false
              this.showSnackbar('Document details successfully updated!')
            } else {
              this.showSnackbar('Document successfully created!')
            }
          }
          this.isUpdateDocuments = false
          this.dialogUpdate = false
          this.fetchDocuments()
          this.formDocumentsFile = new Form({
            file: null,
            title: '',
          })
        })
        .catch((err) => {
          this.formDocumentsFile.$busy = false
          this.formDocumentsFile.$setErrors(this.getValidationErrors(err))
        })
    },

    editItem(item) {
      if (!this.hasPermissions) return false
      this.dialogUpdate = true
      this.isUpdateDocuments = true
      this.formDocumentsFile.title = item.title
      this.formDocumentsFile.file = item.file
      this.formDocumentsFile.folder_id = item.folder_id
      this.formDocumentsFile.id = item.id
    },

    cancelDialogUpdate() {
      this.dialogUpdate = false
      this.isUpdateDocuments = false
      this.formDocumentsFile = new Form({
        file: null,
        title: '',
      })
    },

    cancelDialogDelete() {
      this.dialogDelete = false
    },

    deleteItem(item) {
      this.dialogDelete = true
      this.deleteDocumentId = item.id
    },

    async confirmDelete() {
      await this.deleteDocument(this.deleteDocumentId).then(() => {
        this.deleteTempDocument(this.deleteDocumentId)
        this.showSnackbar('Delete document successfully!')
        this.dialogDelete = false
        this.fetchDocuments()
      })
    },

    reviewFile() {
      const originUrl = this.formDocumentsFile.file?.original_url
      if (originUrl) {
        this.openRemoteFile(originUrl)
      } else {
        if (!this.formDocumentsFile.file) return
        const fileURL = URL.createObjectURL(this.formDocumentsFile.file)
        this.openRemoteFile(fileURL)
      }
    },

    openRemoteFile(fileUrl) {
      const fileExtension = fileUrl.split('.').pop().toLowerCase()
      const supportedExtensions = [
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'ppt',
        'pptx',
      ]

      if (supportedExtensions.includes(fileExtension)) {
        const viewerUrl = `https://docs.google.com/gview?url=${encodeURIComponent(
          fileUrl
        )}&embedded=true`
        window.open(viewerUrl, '_blank')
      } else {
        window.open(fileUrl, '_blank')
      }
    },
  },
}
</script>
<style lang="scss">
.faq-form {
  &__form {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }
}

.row_documents_table {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button_document_table_create {
  text-align: end;
}

.v-dialog__content--active {
  .faq-form {
    &__form {
      max-width: 100%;
    }

    @media (max-width: 768px) {
      &__form {
        max-width: 100%;
      }
    }
  }
}

.table_document_data {
  max-width: 50% !important;
}

.text_uppercase {
  text-transform: uppercase !important;
}

.font_weight_700 {
  font-weight: 700;
}
</style>
